import Parser from 'ninelines-ua-parser';

const parser = new Parser();
let mobile = false;

if (parser.getDevice().type === 'mobile' || parser.getDevice().type === 'tablet' || innerWidth <= 768) {
	mobile = true;
}

export {mobile};

export default {
	KEY_ESC: 27,

	$window: $(window),
	$document: $(document),
	$body: $('body'),

	$header: $('.js-header'),
	$headerAlt: $('.js-header-alt'),
	$headerAltFixed: $('.js-header-alt-fixed'),
	$headerFixed: $('.js-header-fixed'),

	$hamburger: $('.js-hamburger'),
	$mobileMenu: $('.js-mobile-menu'),
	$nav: $('.js-nav'),

	$cardTitle: $('.js-card-title'),

	$hero: $('.js-hero'),
	$heroActionSlider: $('.js-hero-action-slider'),
	$heroContentSlider: $('.js-hero-content-slider'),
	$heroMediaSlider: $('.js-hero-media-slider'),
	$heroSliderControls: $('.js-hero-slider-controls'),
	$heroSliderStatus: $('.js-hero-slider-status'),
	$heroSliderPagination: $('.js-hero-slider-pagination'),

	$doctorVideo: $('.js-doctor-video'),

	$reviewSection: $('.js-review-section'),
	$reviewVideo: $('.js-review-video'),
	$reviewSlider: $('.js-review-slider'),
	$reviewActionSlider: $('.js-review-action-slider'),
	$reviewSliderNav: $('.js-review-slider-nav'),
	$reviewSliderControls: $('.js-review-slider-controls'),
	$reviewSliderStatus: $('.js-review-slider-status'),
	$reviewSliderPagination: $('.js-review-slider-pagination'),

	$certificateSlider: $('.js-certificate-slider'),
	$certificateSliderControls: $('.js-certificate-slider-controls'),

	$carouselSlider: $('.js-carousel-slider'),
	$carouselSliderControls: $('.js-carousel-slider-controls'),

	$aboutSlider: $('.js-about-slider'),
	$aboutSliderStatus: $('.js-about-slider-status'),
	$aboutSliderControls: $('.js-about-slider-controls'),

	$contactsSliderThumb: $('.js-contacts-slider-thumb'),
	$contactsSliderPhoto: $('.js-contacts-slider-photo'),
	$contactsSliderDetails: $('.js-contacts-slider-details'),
	$contactsSliderControls: $('.js-contacts-slider-controls'),

	$scheme: $('.js-scheme'),
	$helper: $('.js-helper'),
	$helperPointer: $('.js-helper-pointer'),
	$helperToggler: $('.js-helper-toggler'),
	$helperPopup: $('.js-helper-popup'),
	$helperPopupClose: $('.js-helper-popup-close'),

	$service: $('.js-service'),
	$serviceItem: $('.js-service-item'),

	$specialist: $('.js-specialist'),
	$specialistVideo: $('.js-specialist-video'),
	$specialistVideoClose: $('.js-specialist-video-close'),
	$specialistVideoPlay: $('.js-specialist-video-play'),

	$modal: $('.js-modal'),
	$modalInner: $('.js-modal-inner'),
	$modalClose: $('.js-modal-close'),
	$modalOpen: $('.js-modal-open'),

	$scrollPane: $('.js-scroll-pane'),
};
