import {TimelineMax} from 'gsap';
import vars from './vars';
import modal from './modal';
import objectFitImages from 'object-fit-images';

require('jquery-mousewheel');
require('jscrollpane');
require('default-passive-events');

// Fixed Header Scrolling
vars.$window.on('scroll', (event) => {
	let underFirstScreen = $(event.currentTarget).scrollTop() > innerHeight;

	if (underFirstScreen && !vars.$headerFixed.hasClass('is-active')) {
		vars.$headerFixed.addClass('is-active');
		vars.$header.addClass('is-scrolling');
	} else if (!underFirstScreen && vars.$headerFixed.hasClass('is-active')) {
		vars.$headerFixed.removeClass('is-active');
		vars.$header.removeClass('is-scrolling');
	}
});

// Main Menu Anchor Navigation
if (vars.$nav.length) {
	vars.$nav.on('click', 'a', (event) => {
		let link = event.currentTarget;

		if (/#/.test(link.href) && location.pathname === link.pathname) {
			event.preventDefault();

			let url = $(link).attr('href');
			let hash = url.split('#')[1];
			let id = `#${hash}`;
			let top = $(id).offset().top;

			$('body,html').animate({scrollTop: top}, 1000, 'swing');
		}
	});
}

// Card Title - First Word Wrapper
if (vars.$cardTitle.length) {
	vars.$cardTitle.each((index, element) => {
		$(element).html(() => {
			let text = $(element).text().trim().split(' ');
			let first = text.shift();

			return (text.length > 0 ? `<span>${first}</span>` : first) + text.join(' ');
		});
	});
}

// HEADER ALT - Toggle from Relative to Fixed position
if (vars.$headerAlt.length) {
	vars.$document.ready(() => {
		vars.$window.trigger('ready');
		vars.$headerAltFixed.toggleClass('is-relative is-fixed');
	});

	vars.$window.on('ready load resize', () => {
		let height = vars.$headerAltFixed.height();

		vars.$headerAlt.height(height);
	});
}

// HAMBURGER MENU
vars.$hamburger.on('click', () => {
	vars.$hamburger.toggleClass('is-active');
	vars.$mobileMenu.toggleClass('is-open');
});

// Hero block animation
if (vars.$hero.length) {
	let tl = new TimelineMax();
	let colors = [
		{
			from: '#0e2b65',
			to: '#0d2c6b',
		},
		{
			from: '#78c418',
			to: '#94ea26',
		},
		{
			from: '#ff0036',
			to: '#ff0018',
		},
	];

	vars.$document.on('heroSlideChanged', () => {
		let id = vars.$hero.attr('data-theme-id') - 1;

		id = id > 2 ? id - 3 : id;

		tl
			.to(vars.$hero, 2, {
				backgroundImage: `linear-gradient(-56deg, ${colors[id].from} 0%, ${colors[id].to} 100%)`,
			});
	});
}

// Scheme: helper block state controll
if (vars.$scheme.length) {
	let headerHeight = vars.$headerFixed.height() || vars.$headerAltFixed.height();

	vars.$window.on('scroll', (event) => {
		let windowScrollTop = $(event.currentTarget).scrollTop();
		let isScrolledDown = windowScrollTop > vars.$scheme.offset().top + innerHeight / 5 - headerHeight;
		let isScrolledUp = windowScrollTop < vars.$scheme.offset().top - innerHeight / 6 - headerHeight;

		if (isScrolledDown && !vars.$scheme.hasClass('is-scrolled-down')) {
			vars.$scheme.removeClass('is-scrolled-up').addClass('is-scrolled-down');
		} else if (!isScrolledDown && isScrolledUp) {
			vars.$scheme.removeClass('is-scrolled-down').addClass('is-scrolled-up');
		}

		if (!isScrolledDown) {
			vars.$scheme.removeClass('is-scrolled-down');
		}

		if (!isScrolledUp) {
			vars.$scheme.removeClass('is-scrolled-up');
		}
	});
}

// MODAL
modal.init();

// Trigger Countries Modal
// modal.open('countries');

// Scroll Pane
$('.carousel__action[data-modal^=certificate]').on('click', () => {
	vars.$scrollPane.jScrollPane({
		contentWidth: '0px',
	});
});

// Specialists show all
$('.js-show-specialists').on('click', (event) => {
	event.preventDefault();

	$(event.currentTarget).parent().hide();
	$('.specialists__block.is-hidden').removeClass('is-hidden');
});
